import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isHieAuth:false,
        theHieUserID:null,
        theHieUserName:null,
        theHieUserRole:null,
        theHieLogin:null,
        theHieprocount:null,
        iscountpro:null,
        isplanspro:null,
        theCN:null,
        INRtoUSD: null, USDtoINR: null, EURtoINR: null, EURtoUSD: null, INRtoEUR: null, USDtoEUR: null, AUDtoINR: null, INRtoAUD: null, AUDtoUSD: null, USDtoAUD: null, AUDtoEUR: null, EURtoAUD: null, GBPtoINR: null, INRtoGBP: null, GBPtoUSD: null, USDtoGBP: null, GBPtoEUR: null, EURtoGBP: null, GBPtoAUD: null, AUDtoGBP: null, CADtoINR: null, INRtoCAD: null, CADtoUSD: null, USDtoCAD: null,CADtoEUR: null, EURtoCAD: null, CADtoAUD: null, AUDtoCAD: null, CADtoGBP: null, GBPtoCAD: null
    }
    componentDidMount(){
        this.isLoggedIn();
        this.isprocount();
        
    }
  
    isLoggedIn = async () => {
        let hieID = JSON.parse(localStorage.getItem('hieID'));
        let hieName = JSON.parse(localStorage.getItem('hieName'));
        let hierole = JSON.parse(localStorage.getItem('hierole'));
        let hieprocount = JSON.parse(localStorage.getItem('hieprocount'));
        if (localStorage.getItem('hieID')) {
            this.setState({
                ...this.state,
                isHieAuth:true,
                theHieUserID:hieID,
                theHieUserName:hieName,
                theHieUserRole:hierole,
                theHieprocount:hieprocount,
            });
        }
    }
    isprocount = async () => {
        let countpro = JSON.parse(localStorage.getItem('countpro'));
        let planspro = JSON.parse(localStorage.getItem('planspro'));
        if (localStorage.getItem('countpro')) {
            this.setState({
                iscountpro: countpro,
                isplanspro: planspro,
              })
        }
    }
    isPageLogin = async () => {
        this.setState({
            ...this.state,
            theHieLogin:null
        });
    }
    isPageActive = async () => {
        this.setState({
            ...this.state,
            theHieLogin:'yes'
        });
    }
    logoutUser = () => {
        localStorage.removeItem('hieID');
        localStorage.removeItem('hieName');
        localStorage.removeItem('hierole');
        localStorage.removeItem('hieprocount');
        localStorage.removeItem('countpro');
        localStorage.removeItem('planspro');
        this.setState({
            ...this.state,
            isHieAuth:false,
            theHieUserID:null,
            theHieUserName:null,
            theHieUserRole:null,
            theHieprocount:null,
            iscountpro:null,
            isplanspro:null,
        })
        window.location.href = '/';
    }
    RegisterUser = async (user,recaptcha_response) => {
        const registerqqq = await Axios.post('Register',{
            user_name:user.name,
            user_email:user.email,
            password:user.password,
            subscribe:user.subscribe, 
            roletype:user.roletype, 
            recaptcha_response:recaptcha_response,
        });
        return registerqqq.data;
    }
    LoginUser = async (user) => {
        const LoginUsersss = await Axios.post('Login',{
            user_email:user.email,
            password:user.password,
        });
        return LoginUsersss.data;
    }
    userdetailsall = async (ids) => {
        const userdetailsss = await Axios.post('userdetails',{
            userids:ids,
        });
        return userdetailsss.data;
    }
    Sellall = async () => {
        const Sellallqqq = await Axios.get('selllist');
        return Sellallqqq.data;
    }
    Agentall = async () => {
        const Agentallqqq = await Axios.get('agentlist');
        return Agentallqqq.data;
    }
    Agentalllist = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Agentalllistqqq = await Axios.get(`agentlistpage/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Agentalllistqqq.data;
    }
   
  Sellallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Sellallpagniationsss = await Axios.get(`selllistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Sellallpagniationsss.data;
    }
    Rentallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Rentallpagniationsss = await Axios.get(`rentlistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Rentallpagniationsss.data;
    }
    Homesallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Homesallpagniationsss = await Axios.get(`homelistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Homesallpagniationsss.data;
    }
    Paysallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Paysallpagniationsss = await Axios.get(`paylistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Paysallpagniationsss.data;
    }
    Highlightedallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const Highlightedallpagniationsss = await Axios.get(`heighlightlistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return Highlightedallpagniationsss.data;
    }
    PGallpagniation = async (pageNumber,lastkeyids,lastkeydate,isindexforward) => {
        const PGallpagniationsss = await Axios.get(`pglistpagniation/${pageNumber}/${lastkeyids}/${lastkeydate}/${isindexforward}`);
        return PGallpagniationsss.data;
    }
    myalllist = async (pageNumber,lastkeyids,userids,lastkeydate,isindexforward) => {
        const myalllistsss = await Axios.get(`myalllistpagniation/${pageNumber}/${lastkeyids}/${userids}/${lastkeydate}/${isindexforward}`);
        return myalllistsss.data;
    }











    Homesall = async () => {
        const Homesallqqq = await Axios.get('homeslist');
        return Homesallqqq.data;
    }
    Paysall = async () => {
        const payslistqqq = await Axios.get('payslist');
        return payslistqqq.data;
    }
    memberstripebooks = async (token,memberid,plan,price,theemail) => {
        const memberstripebooksqqq = await Axios.post('memberstripesss',{
            token:token,
            memberid:memberid,
            plan:plan,
            price:price,
            theemail:theemail,
        });
        return memberstripebooksqqq.data;
    }
    proDetailsall = async (ids) => {
        const proDetailsallqqq = await Axios.post('proDetails',{
            userids:ids,
        });
        return proDetailsallqqq.data;
    }
    prologinsall = async (ids) => {
        const prologinsallqqq = await Axios.post('prologins',{
            userids:ids,
        });
        return prologinsallqqq.data;
    }
    Contactmsgpro = async (user,logids,proemailowner,proids,msgcredits,title) => {
        const Contactmsgproqqq = await Axios.post('contactmsgpro',{
            name: user.name,
            email: user.email,
            phone: user.phone,
            message: user.message,
            logids:logids,
            proemailowner:proemailowner,
            proids:proids,
            msgcredits:msgcredits,
            title:title,
        });
        return Contactmsgproqqq.data;
    }
    Contactmsgview = async (logids,proids,msgcredits,title) => {
        let protypes = 'properties';
        const Contactmsgviewqqq = await Axios.post('contactmsgviewall',{
            logids:logids,
            proids:proids,
            msgcredits:msgcredits,
            title:title,
            protypes:protypes,
        });
        return Contactmsgviewqqq.data;
    }
    Contactmsgviewagent = async (logids,proids,msgcredits,title) => {
        let protypes = 'agent';
        const Contactmsgviewagentqqq = await Axios.post('contactmsgviewall',{
            logids:logids,
            proids:proids,
            msgcredits:msgcredits,
            title:title,
            protypes:protypes,
        });
        return Contactmsgviewagentqqq.data;
    }
    favadsall = async (logids,propertyids,title) => {
        const favadsallqqq = await Axios.post('favadsall',{
            logids:logids,
            propertyids:propertyids,
            title:title,
        });
        return favadsallqqq.data;
    }
    favdeleteall = async (logids,favlistid) => {
        const favdeleteallqqq = await Axios.post('favdeleteall',{
            logids:logids,
            favlistid:favlistid,
        });
        return favdeleteallqqq.data;
    }
    Rentall = async () => {
        const Rentallqqq = await Axios.get('rentlistall');
        return Rentallqqq.data;
    }
    PGall = async () => {
        const PGallqqq = await Axios.get('pglistall');
        return PGallqqq.data;
    }
    proeditDetailsall = async (ids) => {
        const proeditDetailsallqqq = await Axios.post('editDetailsall',{
            userids:ids,
        });
        return proeditDetailsallqqq.data;
    }
    editbasic = async (users,getids,userids,revsidsss) => {
        const editbasicqqq = await Axios.post('editbasicall',{
            propertytitle:users.propertytitle,
            propertydesc:users.propertydesc,
            retailothers:users.retailothers,
            locatedinside:users.locatedinside,
            zonetype:users.zonetype,
            protypescatecommsretail:users.protypescatecommsretail,
            protypescatecomms:users.protypescatecomms,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return editbasicqqq.data;
    }
    editlocation = async (users,getids,userids,revsidsss) => {
        let citychange = users.city.toLowerCase();
        const editlocation = await Axios.post('editlocationall',{
            city:citychange,
            locality:users.locality,
            address:users.address,
            country:users.country,
            countrylabvals:users.countrylabvals,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return editlocation.data;
    }
    editsellprofile = async (users,getids,userids,revsidsss,startdate) => {
        const editsellprofileqqq = await Axios.post('editsellprofileall',{
            bedrooms:users.bedrooms === undefined ? '': users.bedrooms,
            bathrooms:users.bathrooms === undefined ? '': users.bathrooms,
            balconies:users.balconies === undefined ? '': users.balconies,
            carpetarea:users.carpetarea === undefined ? '': users.carpetarea,
            carpetsq:users.carpetsq === undefined ? '': users.carpetsq,
            builtuparea:users.builtuparea === undefined ? '': users.builtuparea,
            builtupareasq:users.builtupareasq === undefined ? '': users.builtupareasq,
            superbuiltarea:users.superbuiltarea === undefined ? '': users.superbuiltarea,
            superbuiltareasq:users.superbuiltareasq === undefined ? '': users.superbuiltareasq,
            poojaroom:users.poojaroom === undefined ? '': users.poojaroom,
            studyroom:users.studyroom === undefined ? '': users.studyroom,
            servantroom:users.servantroom === undefined ? '': users.servantroom,
            storeroom:users.storeroom === undefined ? '': users.storeroom,
            furnished:users.furnished === undefined ? '': users.furnished,
            coveredparking:users.coveredparking === undefined ? '': users.coveredparking,
            openparking:users.openparking === undefined ? '': users.openparking,
            totalfloors:users.totalfloors === undefined ? '': users.totalfloors,
            availabilitystatus:users.availabilitystatus === undefined ? '': users.availabilitystatus,
            ageofproperty:users.ageofproperty === undefined ? '': users.ageofproperty,
            possessionby:users.possessionby === undefined ? '': users.possessionby,
            Yourapartment:users.Yourapartment === undefined ? '': users.Yourapartment,
            propertyonfloor:users.propertyonfloor === undefined ? '': users.propertyonfloor,
            plotarea:users.plotarea === undefined ? '': users.plotarea,
            plotareasq:users.plotareasq === undefined ? '': users.plotareasq,
            lengthofplot:users.lengthofplot === undefined ? '': users.lengthofplot,
            breadthofplot:users.breadthofplot === undefined ? '': users.breadthofplot,
            floorsallowed:users.floorsallowed === undefined ? '': users.floorsallowed,
            boundarywall:users.boundarywall === undefined ? '': users.boundarywall,
            commwashrooms:users.commwashrooms === undefined ? '': users.commwashrooms,
            qualityrating:users.qualityrating === undefined ? '': users.qualityrating,
            entrancewidth:users.entrancewidth === undefined ? '': users.entrancewidth,
            entrancewidthsq:users.entrancewidthsq === undefined ? '': users.entrancewidthsq,
            ceilingheight:users.ceilingheight === undefined ? '': users.ceilingheight,
            ceilingheightsq:users.ceilingheightsq === undefined ? '': users.ceilingheightsq,
            privatewashrooms:users.privatewashrooms === undefined ? '': users.privatewashrooms,
            publicwashrooms:users.publicwashrooms === undefined ? '': users.publicwashrooms,
            notavailable:users.notavailable === undefined ? '': users.notavailable,
            entrance:users.entrance === undefined ? '': users.entrance,
            elevator:users.elevator === undefined ? '': users.elevator,
            stairs:users.stairs === undefined ? '': users.stairs,
            privateparking:users.privateparking === undefined ? '': users.privateparking,
            publicparking:users.publicparking === undefined ? '': users.publicparking,
            multilevelparking:users.multilevelparking === undefined ? '': users.multilevelparking,
            notavailableparking:users.notavailableparking === undefined ? '': users.notavailableparking,
            jewellery:users.jewellery === undefined ? '': users.jewellery,
            gym:users.gym === undefined ? '': users.gym,
            grocery:users.grocery === undefined ? '': users.grocery,
            meat:users.meat === undefined ? '': users.meat,
            clinic:users.clinic === undefined ? '': users.clinic,
            stationery:users.stationery === undefined ? '': users.stationery,
            mobile:users.mobile === undefined ? '': users.mobile,
            clothes:users.clothes === undefined ? '': users.clothes,
            footwear:users.footwear === undefined ? '': users.footwear,
            chemist:users.chemist === undefined ? '': users.chemist,
            otherbusinesstype:users.otherbusinesstype === undefined ? '': users.otherbusinesstype,
            specifybusinesstype:users.Specifybusinesstype === undefined ? '': users.Specifybusinesstype,
            miniseats:users.miniseats === undefined ? '': users.miniseats,
            maxseats:users.maxseats === undefined ? '': users.maxseats,
            cabins:users.cabins === undefined ? '': users.cabins,
            meetingrooms:users.meetingrooms === undefined ? '': users.meetingrooms,
            conferenceroom:users.conferenceroom === undefined ? '': users.conferenceroom,
            receptionarea:users.receptionarea === undefined ? '': users.receptionarea,
            privatepantry:users.privatepantry === undefined ? '': users.privatepantry,
            publicpantry:users.publicpantry === undefined ? '': users.publicpantry,
            notavailablepantry:users.notavailablepantry === undefined ? '': users.notavailablepantry,
            pantrysize:users.pantrysize === undefined ? '': users.pantrysize,
            pantrysizesq:users.pantrysizesq === undefined ? '': users.pantrysizesq,
            facilitiesfurnishing:users.facilitiesfurnishing === undefined ? '': users.facilitiesfurnishing,
            facilitiesair:users.facilitiesair === undefined ? '': users.facilitiesair,
            facilitiesoxygduct:users.facilitiesoxygduct === undefined ? '': users.facilitiesoxygduct,
            facilitiesups:users.facilitiesups === undefined ? '': users.facilitiesups,
            fireextinguisher:users.fireextinguisher === undefined ? '': users.fireextinguisher,
            firesensors:users.firesensors === undefined ? '': users.firesensors,
            sprinklers:users.sprinklers === undefined ? '': users.sprinklers,
            firehose:users.firehose === undefined ? '': users.firehose,
            lifts:users.lifts === undefined ? '': users.lifts,
            family:users.family === undefined ? '': users.family,
            singlemen:users.singlemen === undefined ? '': users.singlemen,
            singlewomen:users.singlewomen === undefined ? '': users.singlewomen,
            roomtype:users.roomtype === undefined ? '': users.roomtype,
            shareroom:users.shareroom === undefined ? '': users.shareroom,
            totalbedspg:users.totalbedspg === undefined ? '': users.totalbedspg,
            availablebedspg:users.availablebedspg === undefined ? '': users.availablebedspg,
            attachedbathroom:users.attachedbathroom === undefined ? '': users.attachedbathroom,
            attachedbalcony:users.attachedbalcony === undefined ? '': users.attachedbalcony,
            girlspg:users.girlspg === undefined ? '': users.girlspg,
            boyspg:users.boyspg === undefined ? '': users.boyspg,
            anypg:users.anypg === undefined ? '': users.anypg,
            suitablestudents:users.suitablestudents === undefined ? '': users.suitablestudents,
            suitableworking:users.suitableworking === undefined ? '': users.suitableworking, 
            dates:startdate === undefined || startdate === null  ? '': startdate,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return editsellprofileqqq.data;
    }
    deleteimgs = async (arrayids,getids,userids,revsidsss) => {
        const deleteimgsqqq = await Axios.post('deleteimgsall',{
            arrayids:arrayids,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return deleteimgsqqq.data;
    }
    deletevideos = async (getids,userids,revsidsss) => {
        const deletevideosqqq = await Axios.post('deletevideosall',{
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return deletevideosqqq.data;
    } 
    editpricing = async (users,getids,userids,revsidsss) => {
        const editpricingqqq = await Axios.post('editpricingall',{
            ownership:users.ownership === undefined ? '': users.ownership,
            expectedprice:users.expectedprice === undefined ? '': users.expectedprice,
            pricesq:users.pricesq === undefined ? '': users.pricesq,
            currency:users.currency === undefined ? '': users.currency,
            inclusiveprice:users.inclusiveprice === undefined ? '': users.inclusiveprice,
            taxandgovt:users.taxandgovt === undefined ? '': users.taxandgovt,
            negotiable:users.negotiable === undefined ? '': users.negotiable,
            maintenance:users.maintenance === undefined ? '': users.maintenance,
            maintenancevality:users.maintenancevality === undefined ? '': users.maintenancevality,
            expectedrental:users.expectedrental === undefined ? '': users.expectedrental,
            bookingamount:users.bookingamount === undefined ? '': users.bookingamount,
            annualpayable:users.annualpayable === undefined ? '': users.annualpayable,
            upsprice:users.upsprice === undefined ? '': users.upsprice,
            preleased:users.preleased === undefined ? '': users.preleased,
            noccete:users.noccete === undefined ? '': users.noccete,
            occupancycere:users.occupancycere === undefined ? '': users.occupancycere,
            backendoffice:users.backendoffice === undefined ? '': users.backendoffice,
            caoffice:users.caoffice === undefined ? '': users.caoffice,
            frontendoffice:users.frontendoffice === undefined ? '': users.frontendoffice,
            smalloffice:users.smalloffice === undefined ? '': users.smalloffice,
            tradersoffice:users.tradersoffice === undefined ? '': users.tradersoffice,
            advocateoffice:users.advocateoffice === undefined ? '': users.advocateoffice,
            agreementtype:users.agreementtype === undefined ? '': users.agreementtype,
            extracharge:users.extracharge === undefined ? '': users.extracharge,
            monthnotice:users.monthnotice === undefined ? '': users.monthnotice,
            durationagree:users.durationagree === undefined ? '': users.durationagree,
            lockperiod:users.lockperiod === undefined ? '': users.lockperiod,
            rentincrese:users.rentincrese === undefined ? '': users.rentincrese,
            laundry:users.laundry === undefined ? '': users.laundry,
            water:users.water === undefined ? '': users.water,
            wifi:users.wifi === undefined ? '': users.wifi,
            housekeeping:users.housekeeping === undefined ? '': users.housekeeping,
            dth:users.dth === undefined ? '': users.dth,
            electricity:users.electricity === undefined ? '': users.electricity,
            fooddetails:users.fooddetails === undefined ? '': users.fooddetails,
            mealtype:users.mealtype === undefined ? '': users.mealtype,
            wkdsbreakfast:users.wkdsbreakfast === undefined ? '': users.wkdsbreakfast,
            wkdslunch:users.wkdslunch === undefined ? '': users.wkdslunch,
            wkdsdinner:users.wkdsdinner === undefined ? '': users.wkdsdinner,
            wkendsbreakfast:users.wkendsbreakfast === undefined ? '': users.wkendsbreakfast,
            wkendslunch:users.wkendslunch === undefined ? '': users.wkendslunch,
            wkendsdinner:users.wkendsdinner === undefined ? '': users.wkendsdinner,
            pets:users.pets === undefined ? '': users.pets,
            visitors:users.visitors === undefined ? '': users.visitors,
            smoking:users.smoking === undefined ? '': users.smoking,
            alcohol:users.alcohol === undefined ? '': users.alcohol,
            party:users.party === undefined ? '': users.party,
            entrytime:users.entrytime === undefined ? '': users.entrytime,
            otherrule:users.otherrule === undefined ? '': users.otherrule,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return editpricingqqq.data;
    }
    editcontact = async (users,getids,userids,revsidsss) => {
        const editcontactqqq = await Axios.post('editcontactall',{
            proemail:users.proemail,
            prophonenumber:users.prophonenumber,
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return editcontactqqq.data;
    }
    editasmarks = async (getids,userids,revsidsss,types) => {
        const editasmarksqqq = await Axios.post('editasmarksall',{
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
            types:types,
        });
        return editasmarksqqq.data;
    }
    editactiveads = async (getids,userids,revsidsss,types) => {
        const editactiveadsqqq = await Axios.post('editactiveadsall',{
            getids:getids,
            userids:userids,
            revsidsss:revsidsss,
            types:types,
        });
        return editactiveadsqqq.data;
    }
    seachall = async (pageNumber,procategory,protypes,protypescategory,country,city,lastkeyids,min,max,beds,bath) => {
        if(pageNumber === 1){
            const lastkeyids = 'empty';
            const seachlistallbooks = await Axios.get(`seachlistall/${pageNumber}/${procategory}/${protypes}/${protypescategory}/${country}/${city}/${lastkeyids}/${min}/${max}/${beds}/${bath}`);
            return seachlistallbooks.data;
        }else{ 
            const seachlistallbooks = await Axios.get(`seachlistall/${pageNumber}/${procategory}/${protypes}/${protypescategory}/${country}/${city}/${lastkeyids}/${min}/${max}/${beds}/${bath}`);
            return seachlistallbooks.data;
        }
    }
    islocation = async () => {
        let CNs = JSON.parse(localStorage.getItem('CN'));
        if (localStorage.getItem('CN')) {
            this.setState({
                ...this.state,
                theCN:CNs,
            });
        }
    }
    currencyallfirst = async () => {
        const currencyallfirstqqq = await Axios.get('currencyallfirstsss');
        return currencyallfirstqqq.data;
    }
    iscurrency = async () => {
        let inrusd = JSON.parse(localStorage.getItem('inr_usd'));
        let usdinr = JSON.parse(localStorage.getItem('usd_inr'));
        let eurinr = JSON.parse(localStorage.getItem('eur_inr'));
        let eurusd = JSON.parse(localStorage.getItem('eur_usd'));
        let inreur = JSON.parse(localStorage.getItem('inr_eur'));
        let usdeur = JSON.parse(localStorage.getItem('usd_eur'));
        let audinr = JSON.parse(localStorage.getItem('aud_inr'));
        let inraud = JSON.parse(localStorage.getItem('inr_aud'));
        let audusd = JSON.parse(localStorage.getItem('aud_usd'));
        let usdaud = JSON.parse(localStorage.getItem('usd_aud'));
        let audeur = JSON.parse(localStorage.getItem('aud_eur'));
        let euraud = JSON.parse(localStorage.getItem('eur_aud'));
        let gbpinr = JSON.parse(localStorage.getItem('gbp_inr'));
        let inrgbp = JSON.parse(localStorage.getItem('inr_gbp'));
        let gbpusd = JSON.parse(localStorage.getItem('gbp_usd'));
        let usdgbp = JSON.parse(localStorage.getItem('usd_gbp'));
        let gbpeur = JSON.parse(localStorage.getItem('gbp_eur'));
        let eurgbp = JSON.parse(localStorage.getItem('eur_gbp'));
        let gbpaud = JSON.parse(localStorage.getItem('gbp_aud'));
        let audgbp = JSON.parse(localStorage.getItem('aud_gbp'));
        let cadinr = JSON.parse(localStorage.getItem('cad_inr'));
        let inrcad = JSON.parse(localStorage.getItem('inr_cad'));
        let cadusd = JSON.parse(localStorage.getItem('cad_usd'));
        let usdcad = JSON.parse(localStorage.getItem('usd_cad'));
        let cadeur = JSON.parse(localStorage.getItem('cad_eur'));
        let eurcad = JSON.parse(localStorage.getItem('eur_cad'));
        let cadaud = JSON.parse(localStorage.getItem('cad_aud'));
        let audcad = JSON.parse(localStorage.getItem('aud_cad'));
        let cadgbp = JSON.parse(localStorage.getItem('cad_gbp'));
        let gbpcad = JSON.parse(localStorage.getItem('gbp_cad'));
        if (localStorage.getItem('inr_usd')) {
            this.setState({
                ...this.state,
                INRtoUSD: inrusd, USDtoINR: usdinr, EURtoINR: eurinr, EURtoUSD: eurusd, INRtoEUR: inreur, USDtoEUR: usdeur, AUDtoINR: audinr, INRtoAUD: inraud, AUDtoUSD: audusd, USDtoAUD: usdaud, AUDtoEUR: audeur, EURtoAUD: euraud, GBPtoINR: gbpinr, INRtoGBP: inrgbp, GBPtoUSD: gbpusd, USDtoGBP: usdgbp, GBPtoEUR: gbpeur, EURtoGBP: eurgbp, GBPtoAUD: gbpaud, AUDtoGBP: audgbp, CADtoINR: cadinr, INRtoCAD: inrcad, CADtoUSD: cadusd, USDtoCAD: usdcad,CADtoEUR: cadeur, EURtoCAD: eurcad, CADtoAUD: cadaud, AUDtoCAD: audcad, CADtoGBP: cadgbp, GBPtoCAD: gbpcad
            });
        }
    }
    currencysecondall = async (countrynames,INR_USD,USD_INR,EUR_INR,EUR_USD,INR_EUR,USD_EUR,AUD_INR,INR_AUD,AUD_USD,USD_AUD,AUD_EUR,EUR_AUD,GBP_INR,INR_GBP,GBP_USD,USD_GBP,GBP_EUR,EUR_GBP,GBP_AUD,AUD_GBP,CAD_INR,INR_CAD,CAD_USD,USD_CAD,CAD_EUR,EUR_CAD,CAD_AUD,AUD_CAD,CAD_GBP,GBP_CAD) => {
        const currencysecondallqqq = await Axios.post('currencysecondallsss',{
            countrynames: countrynames,
            INR_USD: INR_USD,
            USD_INR: USD_INR,
            EUR_INR: EUR_INR,
            EUR_USD: EUR_USD,
            INR_EUR: INR_EUR,
            USD_EUR: USD_EUR,
            AUD_INR: AUD_INR,
            INR_AUD: INR_AUD,
            AUD_USD: AUD_USD,
            USD_AUD: USD_AUD,
            AUD_EUR: AUD_EUR,
            EUR_AUD: EUR_AUD,
            GBP_INR: GBP_INR,
            INR_GBP: INR_GBP,
            GBP_USD: GBP_USD,
            USD_GBP: USD_GBP,
            GBP_EUR: GBP_EUR,
            EUR_GBP: EUR_GBP,
            GBP_AUD: GBP_AUD,
            AUD_GBP: AUD_GBP,
            CAD_INR: CAD_INR,
            INR_CAD: INR_CAD,
            CAD_USD: CAD_USD,
            USD_CAD: USD_CAD,
            CAD_EUR: CAD_EUR,
            EUR_CAD: EUR_CAD,
            CAD_AUD: CAD_AUD,
            AUD_CAD: AUD_CAD,
            CAD_GBP: CAD_GBP,
            GBP_CAD: GBP_CAD,
        });
        return currencysecondallqqq.data;
    }
    addvideourl = async (books,userids) => {
        const formData = new FormData();
        formData.append('url',books.url);
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        const addvideourl = await Axios.post('addvideourl',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return addvideourl.data;
    }
    addvideouploadall = async (books,userids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('avatarfile',books.videofile);
        formData.append('videofileactive',books.videofileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        const addvideouploadallqqq = await Axios.post('addvideoupload',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return addvideouploadallqqq.data;
    }
    deleteimgsvideo = async (getids,orginalids,allvideoids,revids) => {
        const deleteimgsvideoqqq = await Axios.post('deleteimgsvideoall',{
            getids:getids,
            orginalids:orginalids,
            allvideoids:allvideoids,
            revids:revids,
        });
        return deleteimgsvideoqqq.data;
    }
    editvideourl = async (books,getids,orginalids,allvideoids,revids) => {
        const formData = new FormData();
        formData.append('url',books.url);
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('getids',getids);
        formData.append('orginalids',orginalids);
        formData.append('allvideoids',allvideoids);
        formData.append('revids',revids);
        const editvideourlqqq = await Axios.post('editvideourlall',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editvideourlqqq.data;
    }
    editvideouploadall = async (books,getids,orginalids,allvideoids,revids) => {
        const formData = new FormData();
        formData.append('url',books.url);
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('avatarfile',books.videofile);
        formData.append('videofileactive',books.videofileactive);
        formData.append('abouts',books.abouts);
        formData.append('getids',getids);
        formData.append('orginalids',orginalids);
        formData.append('allvideoids',allvideoids);
        formData.append('revids',revids);
        const editvideouploadallqqq = await Axios.post('editvideouploadall',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editvideouploadallqqq.data;
    }
    videoall = async () => {
        const videoallqqq = await Axios.get('videoalllist');
        return videoallqqq.data;
    }
    editactivevideos = async (userids,revids,types,orginalids,allvideoids) => {
        const editactivevideosqqq = await Axios.post('editactivevideosall',{
            userids:userids,
            revids:revids,
            types:types,
            orginalids:orginalids,
            allvideoids:allvideoids,
        });
        return editactivevideosqqq.data;
    }
    proDetailsvideoall = async (userids) => {
        const proDetailsvideoallqqq = await Axios.post('proDetailsvideoall',{
            userids:userids,
        });
        return proDetailsvideoallqqq.data;
    }
    AgentRegisterUser = async (books,recaptcha_response,users) => {
        const formData = new FormData();
        formData.append('user_name',users.name);
        formData.append('user_email',books.email);
        formData.append('avatar',users.singlefile);
        formData.append('singlefileactive',users.singlefileactive);
        formData.append('password',books.password);
        formData.append('mobile',users.mobile);
        formData.append('abouts',users.abouts);
        formData.append('website',users.website);
        formData.append('aglocation',users.aglocation);
        formData.append('agfacebook',users.agfacebook);
        formData.append('aginstagram',users.aginstagram);
        formData.append('agtwitter',users.agtwitter);
        formData.append('recaptcha_response',recaptcha_response);
        const AgentRegisterUserqqq = await Axios.post('agentregisteruser',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AgentRegisterUserqqq.data;
    }
    AgentupdateUser = async (books,userids,agentarrayids) => {
        const formData = new FormData();
        formData.append('user_name',books.name);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('mobile',books.mobile);
        formData.append('abouts',books.abouts);
        formData.append('website',books.website);
        formData.append('aglocation',books.aglocation);
        formData.append('agfacebook',books.agfacebook);
        formData.append('aginstagram',books.aginstagram);
        formData.append('agtwitter',books.agtwitter);
        formData.append('userids',userids);
        formData.append('agentarrayids',agentarrayids);
        const AgentupdateUserqqq = await Axios.post('agentupdateuser',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AgentupdateUserqqq.data;
    }
    agentdetailsall = async (getids) => {
        const agentdetailsallqqq = await Axios.post('agentdetails',{
            getids:getids,
        });
        return agentdetailsallqqq.data;
    }
    addnewsletters = async (userinfo) => {
        const addnewslettersqqq = await Axios.post('addnewsletterssss',{
            firstname:userinfo.firstname,
            lastname:userinfo.lastname,
            email:userinfo.email,
            myinbox:userinfo.myinbox,
        });
        return addnewslettersqqq.data;
    }
    Highlightedstripe = async (token,memberid,username,price,theemail,userids) => {
        const Highlightedstripeqqq = await Axios.post('highlightedstripesss',{
            token:token,
            memberid:memberid,
            username:username,
            price:price,
            theemail:theemail,
            userids:userids,
        });
        return Highlightedstripeqqq.data;
    }
    Highlightedall = async () => {
        const Highlightedallqqq = await Axios.get('Highlightedall');
        return Highlightedallqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('forgetpasswordsss',{
            email:user.useremail,
        });
        return loginForget.data;
    }
    ChangePasswordall = async (user,passkey) => {
        const ChangePasswordallsss = await Axios.post('ChangePasswordallqqq',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return ChangePasswordallsss.data;
    }
    AgForgetpass = async (user) => {
        const AgForgetpassqqq = await Axios.post('AgForgetpass',{
            email:user.useremail,
        });
        return AgForgetpassqqq.data;
    }
    AgChangePassall = async (user,passkey) => {
        const AgChangePassallqqq = await Axios.post('AgChangePassallsss',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return AgChangePassallqqq.data;
    }
    feedbacklistsssall = async (userInfo,phone,userids,typesss) => {
        const feedbacklistsssallqqq = await Axios.post('feedbacklistsssallsss',{
            name: userInfo.name,
            email: userInfo.email,
            yes: userInfo.yes,
            no: userInfo.no,
            advertiser: userInfo.advertiser,
            desc: userInfo.desc,
            sold: userInfo.sold,
            fakephotos: userInfo.fakephotos,
            incorrectlocation: userInfo.incorrectlocation,
            broker: userInfo.broker,
            others: userInfo.others,
            incorrectprice: userInfo.incorrectprice,
            incorrectsale: userInfo.incorrectsale,
            wrong: userInfo.wrong,
            switched: userInfo.switched,
            phone: phone,
            userids: userids,
            typesss: typesss,
        });
        return feedbacklistsssallqqq.data;
    }
    emailverifysall = async (users,userids) => {
        const emailverifysallqqq = await Axios.post('emailverifysallsss',{
            sms: users.sms,
            userids: userids,
        });
        return emailverifysallqqq.data;
    }
    feedbacksadminall = async () => {
        const feedbacksadminallqqq = await Axios.get(`feedbacksadminallsss`);
        return feedbacksadminallqqq.data;
    }
    Deletefeedbackslistall = async (ids) => {
        const Deletefeedbackslistallqqq = await Axios.post('Deletefeedbackslistallsss',{
            revsidsss:ids,
        });
        return Deletefeedbackslistallqqq.data;
    }
    proidsssall = async (users) => {
        const proidsssallqqq = await Axios.post('proidsssallsss',{
            ids:users.ids,
        });
        return proidsssallqqq.data;
    }
    Highlightedchange = async (proadids,revsids,userids,highligtid) => {
        const Highlightedchangeqqq = await Axios.post('Highlightedchangesss',{
            proadids:proadids,
            revsids:revsids,
            userids:userids,
            highligtid:highligtid,
        });
        return Highlightedchangeqqq.data;
    }
    addarticlsall = async (userids) => {
        const addarticlsallqqq = await Axios.post('addarticlsallsss',{
            userids:userids,
        });
        return addarticlsallqqq.data;
    }
    addarticleurl = async (books,userids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        const addarticleurlqqq = await Axios.post('addarticleurlsss',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return addarticleurlqqq.data;
    }
    addblogsall = async (userids) => {
        const addblogsallqqq = await Axios.post('addblogsallsss',{
            userids:userids,
        });
        return addblogsallqqq.data;
    }
    addblogurl = async (books,userids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        const addblogurlqqq = await Axios.post('addblogurlsss',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return addblogurlqqq.data;
    }
    editarticleurl = async (books,userids,revids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        formData.append('revids',revids);
        const editarticleurlqqq = await Axios.post('editarticleurlsss',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editarticleurlqqq.data;
    }
    editblogurl = async (books,userids,revids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('singlefileactive',books.singlefileactive);
        formData.append('abouts',books.abouts);
        formData.append('userids',userids);
        formData.append('revids',revids);
        const editblogurlqqq = await Axios.post('editblogurlsss',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editblogurlqqq.data;
    }
    editactivearticle = async (userids,revids,types) => {
        const editactivearticleqqq = await Axios.post('editactivearticlesss',{
            userids:userids,
            revids:revids,
            types:types,
        });
        return editactivearticleqqq.data;
    }
    editactiveBlog = async (userids,revids,types) => {
        const editactiveBlogqqq = await Axios.post('editactiveBlogsss',{
            userids:userids,
            revids:revids,
            types:types,
        });
        return editactiveBlogqqq.data;
    }
    addsellersall = async (userids) => {
        const addsellersallqqq = await Axios.post('addsellersallsss',{
            userids:userids,
        });
        return addsellersallqqq.data;
    }
    editactiveseller = async (userids,revids,types) => {
        const editactivesellerqqq = await Axios.post('editactivesellersss',{
            userids:userids,
            revids:revids,
            types:types,
        });
        return editactivesellerqqq.data;
    }
    addsocialsall = async (userids) => {
        const addsocialsallqqq = await Axios.post('addsocialsallsss',{
            userids:userids,
        });
        return addsocialsallqqq.data;
    }
    addsocialsurl = async (socials,userids) => {
        const addsocialsurlqqq = await Axios.post('addsocialsurlsss',{
            facebook: socials.facebook,
            twitter: socials.twitter,
            linkedin: socials.linkedin,
            instagram: socials.instagram,
            youtube: socials.youtube,
            userids:userids,
        });
        return addsocialsurlqqq.data;
    }
    deleteimgsseller = async (arrayids,userids,revsidsss) => {
        const deleteimgssellerqqq = await Axios.post('deleteimgssellerall',{
            arrayids:arrayids,
            userids:userids,
            revsidsss:revsidsss,
        });
        return deleteimgssellerqqq.data;
    }
    deletevideosseller = async (userids,revsidsss) => {
        const deletevideossellerqqq = await Axios.post('deletevideossellerall',{
            userids:userids,
            revsidsss:revsidsss,
        });
        return deletevideossellerqqq.data;
    } 
    Articleall = async () => {
        const Articleallqqq = await Axios.get('Articleallsss');
        return Articleallqqq.data;
    }
    Blogall = async () => {
        const Blogallqqq = await Axios.get('Blogallsss');
        return Blogallqqq.data;
    }
    articledetailsall = async (uniqueids) => {
        const articledetailsallqqq = await Axios.post('articledetailsall',{
            uniqueids:uniqueids,
        });
        return articledetailsallqqq.data;
    }
    blogdetailsall = async (uniqueids) => {
        const blogdetailsallqqq = await Axios.post('blogdetailsall',{
            uniqueids:uniqueids,
        });
        return blogdetailsallqqq.data;
    }
    highlightreceiptall = async (userids) => {
        const highlightreceiptallsss = await Axios.post('highlightreceiptall',{
            userids:userids,
        });
        return highlightreceiptallsss.data;
    }




    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            isPageLogin:this.isPageLogin,
            isPageActive:this.isPageActive,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            userdetailsall:this.userdetailsall,
            isprocount:this.isprocount,
            Sellall:this.Sellall,
            Homesall:this.Homesall,
            memberstripebooks:this.memberstripebooks,
            Paysall:this.Paysall,
            proDetailsall:this.proDetailsall,
            prologinsall:this.prologinsall,
            Contactmsgpro:this.Contactmsgpro,
            Contactmsgview:this.Contactmsgview,
            Contactmsgviewagent:this.Contactmsgviewagent,
            favadsall:this.favadsall,
            favdeleteall:this.favdeleteall,
            Rentall:this.Rentall,
            PGall:this.PGall,
            proeditDetailsall:this.proeditDetailsall,
            editbasic:this.editbasic,
            editlocation:this.editlocation,
            editsellprofile:this.editsellprofile,
            deleteimgs:this.deleteimgs,
            deletevideos:this.deletevideos,
            editpricing:this.editpricing,
            editcontact:this.editcontact,
            editasmarks:this.editasmarks,
            editactiveads:this.editactiveads,
            seachall:this.seachall,
            islocation:this.islocation,
            currencyallfirst:this.currencyallfirst,
            iscurrency:this.iscurrency,
            currencysecondall:this.currencysecondall,
            addvideourl:this.addvideourl,
            addvideouploadall:this.addvideouploadall,
            deleteimgsvideo:this.deleteimgsvideo,
            editvideourl:this.editvideourl,
            editvideouploadall:this.editvideouploadall,
            videoall:this.videoall,
            editactivevideos:this.editactivevideos,
            proDetailsvideoall:this.proDetailsvideoall,
            AgentRegisterUser:this.AgentRegisterUser,
            AgentupdateUser:this.AgentupdateUser,
            Agentall:this.Agentall,
            agentdetailsall:this.agentdetailsall,
            addnewsletters:this.addnewsletters,
            Highlightedstripe:this.Highlightedstripe,
            Highlightedall:this.Highlightedall,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            AgForgetpass:this.AgForgetpass,
            AgChangePassall:this.AgChangePassall,
            feedbacklistsssall:this.feedbacklistsssall,
            emailverifysall:this.emailverifysall,
            feedbacksadminall:this.feedbacksadminall,
            Deletefeedbackslistall:this.Deletefeedbackslistall,
            proidsssall:this.proidsssall,
            Highlightedchange:this.Highlightedchange,
            addarticlsall:this.addarticlsall,
            addarticleurl:this.addarticleurl,
            addblogsall:this.addblogsall,
            addblogurl:this.addblogurl,
            editarticleurl:this.editarticleurl,
            editblogurl:this.editblogurl,
            editactivearticle:this.editactivearticle,
            editactiveBlog:this.editactiveBlog,
            addsellersall:this.addsellersall,
            editactiveseller:this.editactiveseller,
            addsocialsall:this.addsocialsall,
            addsocialsurl:this.addsocialsurl,
            deleteimgsseller:this.deleteimgsseller,
            deletevideosseller:this.deletevideosseller,
            Articleall:this.Articleall,
            Blogall:this.Blogall,
            articledetailsall:this.articledetailsall,
            blogdetailsall:this.blogdetailsall,
            Agentalllist:this.Agentalllist,
            
            Sellallpagniation:this.Sellallpagniation,
            Rentallpagniation:this.Rentallpagniation,
            Homesallpagniation:this.Homesallpagniation,
            Paysallpagniation:this.Paysallpagniation,
            Highlightedallpagniation:this.Highlightedallpagniation,
            PGallpagniation:this.PGallpagniation,
            myalllist:this.myalllist,
            highlightreceiptall:this.highlightreceiptall,
            

           
          
           
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;
//export const WEB_URL_UPLOAD = "http://192.168.0.129/demo/uploads/";
//export const WEB_URL = "http://192.168.0.128:8080/";

export const WEB_URL_UPLOAD = "https://hiehomes.com/uploads/";
export const WEB_URL = "https://hiehomes.com/";



export const API_KEY_STRIPE = "pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8";

 export const CAPTCHA_SITE_KEY = "6LdnAysbAAAAAEVl9heIaogAfZ22_rRhVx4oPx13";
import React, { Suspense, lazy } from 'react';
import {Routes, Route, Link} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';

const Home = lazy(() => import('./page/Home'));
const Sell = lazy(() => import('./page/Sell'));
const CommingSoon = lazy(() => import('./page/CommingSoon'));
const Featured = lazy(() => import('./page/Featured'));
const Rent = lazy(() => import('./page/Rent'));
const Pg = lazy(() => import('./page/Pg'));
const HomesAll = lazy(() => import('./page/HomesAll'));
const ContactPage = lazy(() => import('./page/ContactPage'));
const About = lazy(() => import('./page/About'));
const HighLighted = lazy(() => import('./page/HighLighted'));
const PropertyVideos = lazy(() => import('./page/PropertyVideos'));
const PropertyVideosView = lazy(() => import('./page/PropertyVideosView'));
const NotFound = lazy(() => import("./page/NotFound"));
const ChangePassword = lazy(() => import("./page/login/ChangePassword"));
const Logout = lazy(() => import("./page/login/Logout"));
const PostProperty = lazy(() => import("./page/property/PostProperty"));
const PropertyDetails = lazy(() => import("./page/view/PropertyDetails"));
const MyAccount = lazy(() => import("./page/account/MyAccount"));
const SearchPage = lazy(() => import("./page/search/SearchPage"));
const CountryCurrency = lazy(() => import("./page/CountryCurrency"));
const AddVideo = lazy(() => import("./page/video/AddVideo"));
const AgentRegister = lazy(() => import("./page/agent/AgentRegister"));
const AgentLogin = lazy(() => import("./page/agent/AgentLogin"));
const AgentForget = lazy(() => import("./page/agent/AgentForget"));
const AgChangePass = lazy(() => import("./page/agent/AgChangePass"));
const FindAgents = lazy(() => import("./page/FindAgents"));
const AgentDetails = lazy(() => import("./page/AgentDetails"));
const Article = lazy(() => import("./page/Article"));
const Blog = lazy(() => import("./page/Blog"));
const ArticleList = lazy(() => import("./page/ArticleList"));
const BlogList = lazy(() => import("./page/BlogList"));
const PrivacyPolicy = lazy(() => import('./page/PrivacyPolicy'));
const TermsofUse = lazy(() => import('./page/TermsofUse'));
const RefundPolicy = lazy(() => import('./page/RefundPolicy'));

function App() {
  
  return (<>   
    <>
      <Suspense fallback={<div className="divLoaderwww"> Loading... </div>}>
        <ToastContainer />
        <CountryCurrency />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/changePassword/:passkey" element={<ChangePassword />} />
          <Route path="/postProperty" element={<PostProperty />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/Article" element={<Article />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Featured" element={<Featured />} />
          <Route path="/Rent" element={<Rent />} />
          <Route path="/Pg" element={<Pg />} />
          <Route path="/commingSoon" element={<CommingSoon />} />
          <Route path="/HomesAll" element={<HomesAll />} />
          <Route path="/ContactPage" element={<ContactPage />} />
          <Route path="/About" element={<About />} />
          <Route path="/highLighted" element={<HighLighted />} />
          <Route path="/findAgents" element={<FindAgents />} />
          <Route path="/searchPage/:details" element={<SearchPage />} />
          <Route path="/addVideo" element={<AddVideo />} />
          <Route path="/agentRegister/*" element={<AgentRegister />} />
          <Route path="/agentLogin" element={<AgentLogin />} />
          <Route path="/agentForget" element={<AgentForget />} />
          <Route path="/agentChangePassword/:passkey" element={<AgChangePass />} />
          <Route path="/agentDetails/:ids/:title" element={<AgentDetails />} />
          <Route path="/ArticleList/:ids/:title" element={<ArticleList />} />
          <Route path="/BlogList/:ids/:title" element={<BlogList />} />
          <Route path="/propertyVideos" element={<PropertyVideos />} />
          <Route path="/propertyVideosView/:ids/:title" element={<PropertyVideosView />} />
          <Route path="/propertyDetails/:ids/:title" element={<PropertyDetails />} />
          <Route path="myhomes/*" element={<MyAccount />} />
          <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="/Terms-Of-Service" element={<TermsofUse />} />
          <Route path="/Cancellation-&-Refund-Policy" element={<RefundPolicy />} />


          <Route path="*" element={<NotFound />} />

        </Routes>
      </Suspense>
      {/* <Sticky /> */}
    </>
    
    </>
  );
}
export default App;
